import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Browsernav from "../Components/Browsernav";
import Mobilenav from "../Components/Mobilenav";
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from "firebase";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
// import ScriptTag from "react-script-tag";

function ProcessFunds() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] =
    useContext(GlobalContext);
  const [image, setimage] = useState(null);
  const [url, seturl] = useState("");
  const [progress, setprogress] = useState(0);
  const [imagepresnt, setimagepresent] = useState(false);
  const [prevfile, setprevFile] = useState("");

  useEffect(() => {
    console.log(depositinfo);
  }, []);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      setimagepresent(true);
      setprevFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleUpload = () => {
    updatehistory("null");
    setprogress(0);
    setimagepresent(false);
    // sendAdminMaiil();

    Swal.fire(
      "Succesful Transaction!",
      "Your account will be credited once the payment is recieved.",
      "success"
    );
    // alert("we will we credit your balance shortly");
    setimage(null);
    history("/real");
    // if (!imagepresnt) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'No Image Was Selected!',
    //     })
    //     return
    // }
    // //check wether amt is empty or file is not selected
    // const uploadTask = storage.ref(`images/${image.name}`).put(image);
    // uploadTask.on(
    //     "state_changed",
    //     (snapshot) => {
    //         const progress = Math.round(
    //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //         );
    //         setprogress(progress);
    //     },
    //     (error) => {
    //         console.log(error);
    //     },
    //     () => {
    //         storage
    //             .ref("images")
    //             .child(image.name)
    //             .getDownloadURL()
    //             .then((url) => {
    //                 seturl(url);
    //                 updatehistory(url);
    //                 setprogress(0);
    //                 setimagepresent(false)
    //                 sendAdminMaiil()

    //                 Swal.fire(
    //                     'Succesful Transaction!',
    //                     'Your account will be credited once the payment is recieved.',
    //                     'success'
    //                 )
    //                 // alert("we will we credit your balance shortly");
    //                 setimage(null);
    //                 history("/real")
    //             });
    //     }
    // );
  };

  const sendAdminMaiil = async () => {
    var templateParams = {
      message: `${
        userdetails.email
      } have successfully Deposited $${depositinfo.amount
        .toString()
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}  check admin dashboard to verify`,
    };

    //         // setloading(false)
    //handleClick1()

    await emailjs
      .send(
        "service_zvu1h4k",
        "template_sw3wd5g",
        templateParams,
        "qUOF37s42i9PVN5RY"
      )
      .then(
        function (response) {
          //         // setloading(false)
        },
        function (err) {
          //         // setloading(false)
          alert("FAILED...", err);
        }
      );
  };
  const updatehistory = async (urls) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      Deposithistory: firebase.firestore.FieldValue.arrayUnion({
        image: urls,
        date: Date.now(),
        amt: depositinfo.amount,
        mode: "Deposit",
      }),
    });
  };

  return (
    <div>
      <Helmet>
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title> Grandx-protrades Main </title>
        <meta
          name="description"
          content="            
														"
        />

        <link
          rel="shortcut icon"
          type="image/png"
          sizes="32x32"
          href="assets/img/favicon.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="16x16"
          href="assets/img/favicon.png"
        />
        <link rel="canonical" href="dashboard" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
        />
        <link
          href="/dashboard/assets/css/css.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dashboard/assets/inner.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dashboard/assets/app.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dashboard/assets/cs.css"
        />
        <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
        <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
        <script async="" src="//dashboard/assets/js/js.js"></script>
        <script
          type="text/javascript"
          src="//dashboard/assets/js/conversion.js"
        ></script>
        <script
          type="text/javascript"
          src="/dashboard/assets/js/sweet-alert.js"
        ></script>
        <script
          type="text/javascript"
          async=""
          src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"
        ></script>

        <script src="/dashboard/assets/inner.js"></script>
        <script src="/js/app.js"></script>
        <script
          type="text/javascript"
          async=""
          src="//dashboard/assets/js/conversion_async.js"
        ></script>
        <script
          type="text/javascript"
          async=""
          src="//dashboard/assets/js/watch.js"
        ></script>
        <script async="" src="//dashboard/assets/js/analytics.js"></script>
        <script src="/dashboard/assets/inner.js"></script>
        <script src="/dashboard/assets/js/vendor.js"></script>
        <script src="/dashboard/assets/app.js"></script>

        <script type="text/javascript" src="validation.min.js"></script>
        <script type="text/javascript" src="trade_script.js"></script>
        <script type="text/javascript" src="trade_script2.js"></script>
        <script src="/js/sweet-alert.js"></script>
        <script src="https://use.fontawesome.com/4b789087e7.js"></script>
      </Helmet>
      <Mobilenav />
      <div id="google_translate_element"></div>
      {/* <ScriptTag type="text/javascript">
                    {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
                </ScriptTag>
                <ScriptTag
                    type="text/javascript"
                    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                ></ScriptTag> */}
      <div className="pusher" aria-hidden="false">
        <nav className="top-nav inner inner-new">
          <div className="top-bar" style={{ height: "50px" }}>
            <div className="head-row clearfix">
              <div className="float-left ">
                <div className="logosm">
                  <a href="dashboard.php">
                    <img src="assets/img/2.png" alt="ProvidusOption" />
                  </a>
                </div>
                {/*<a href="" class="top-bar-nav dinb"><i class="help-open"></i> <span>Support</span></a>*/}
                <div
                  className="ui item dropdown dropdown-call dinb"
                  tabIndex={0}
                >
                  <div className="top-bar-nav">
                    <div className="phone-open" />
                  </div>
                  <div className="menu transition hidden" tabIndex={-1}>
                    <div className="item">
                      <div className="phone-notice">
                        Please input your phone number with
                        <br />
                        country code and we will immediately contact you.
                      </div>
                      <div>
                        +
                        <input
                          className="call-input"
                          placeholder="phone"
                          type="text"
                          maxLength={20}
                          force-integer
                          id="callback"
                          style={{ width: "200px" }}
                        />
                        <button
                          className="button ui green-bm"
                          id="call_btn"
                          onclick="callBack()"
                        >
                          Call back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="menu-toggle float-right">
                <i />
              </span>
              <div className="float-right nav smcapy-nav">
                <div className="ui item lang pointing dropdown" tabIndex={0}>
                  <input type="hidden" name="lang" />
                  <div className="default text">
                    <div
                      id="google_translate_element"
                      className="google-trans"
                    />
                  </div>
                  <i className="dropdown icon" />
                </div>
                <div className="item">
                  <span style={{ color: "#eee" }}>Account Status:</span>
                  <span style={{ color: "#0f0" }} className="blinkgreen">
                    <i className="fa fa-check-circle" /> Active
                  </span>
                </div>
                <div className="item">
                  <a
                    className="ui button op link a-green-hover"
                    href="logout.php"
                  >
                    <i className="fa fa-power-off" /> Exit
                  </a>
                </div>
                <div className="item">
                  <a
                    className="ui button op nobold"
                    style={{ background: "#07b18c" }}
                    href="/deposit"
                  >
                    {" "}
                    Account Funding
                  </a>
                </div>
                {/*<div class="item"><a class="ui button op nobold" style="background:#E95524" href="acct-funding.php"><i class="fa fa-money"></i> Demo Account 0.00 USD</a></div> */}
              </div>
            </div>
          </div>
          <div className="top-line clearfix">
            <div className="row-30">
              <ul className="icon-nav">
                <li className="active">
                  <a
                    target="_blank"
                    title="Charting tools"
                    className="technic"
                    href="chart.php"
                    style={{ color: "#FFFFFF" }}
                  >
                    <i className="fa fa-bar-chart" />
                  </a>
                </li>
                <li className="active">
                  <a
                    title="Fundamental analysis"
                    className="fundament"
                    href="fundamental-analysis.php"
                    target="_blank"
                    style={{ color: "#FFFFFF" }}
                  >
                    <i className="fa fa-pie-chart" />
                  </a>
                </li>
                <li className="active">
                  <a
                    title="News"
                    className="expert"
                    href="news.php"
                    target="_blank"
                    style={{ color: "#FFFFFF" }}
                  >
                    <i className="fa fa-tv" />
                  </a>
                </li>
                <li className="active">
                  <a
                    title="Technical Analysis"
                    className="ideas"
                    href="technical-analysis.php"
                    target="_blank"
                    style={{ color: "#FFFFFF" }}
                  >
                    <i className="fa fa-signal" />
                  </a>
                </li>
                <li className="active">
                  <a
                    title="Economic calendar"
                    className="calendar"
                    href="calendar.php"
                    target="_blank"
                    style={{ color: "#FFFFFF" }}
                  >
                    <i className="fa fa-calendar" />
                  </a>
                </li>
              </ul>
            </div>
            <Browsernav />
          </div>
        </nav>
        <div className="root-content">
          <div className="pusher push-trading">
            <div>
              <div className="pusher pusher-min-400">
                <section className="img-bg-section">
                  <div className="row">
                    <ul className="tabs">
                      <li>
                        <a className="active">Account Funding</a>
                      </li>
                      <li>
                        <a>Withdrawals</a>
                      </li>
                    </ul>
                    <div className="mob-tab-nav mob-main-tabs">
                      <div
                        className="ui not_select dropdown mob-tabular"
                        tabIndex={0}
                      >
                        <div className="text default">Account Funding</div>
                        <i className="dropdown icon" />
                        <div className="menu" tabIndex={-1}>
                          <a className="item">Withdrawals</a>
                        </div>
                      </div>
                    </div>
                    <div className="top-info">
                      <h2 className="title">Account Funding Details </h2>
                    </div>
                  </div>
                  <span className="blue-arrow" />
                </section>
                <div style={{}}>
                  <section className="content-box">
                    <div className="row">
                      {depositinfo.coin == "eth" && (
                        <div className="funding-method-wrap">
                          <div aria-hidden="false" className>
                            <div className="pay-tabs-content">
                              <div className="ui bottom attached tab segment active">
                                <h2 className="title">
                                  Deposit using Ethereum
                                </h2>
                                <div
                                  className="withdraw-form clearfix bitcoin-from ng-scope"
                                  ng-controller="PayBTC"
                                >
                                  <div>
                                    <div
                                      className="btc-amount"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div>
                                        <center>
                                          <img src="../assets/eth.png" />
                                        </center>
                                        <span>Amount in base currency:</span>
                                        <b className="ui green inverted header">
                                          {depositinfo.amount}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="center-button">
                                      <div className="line">
                                        <p>
                                          <span>
                                            Note: Make sure that you have sent
                                            the required amount before clicking
                                            on the confirm deposit button.
                                          </span>
                                        </p>
                                      </div>
                                      <div className="line">
                                        <label>
                                          Official Ethereum Wallet Address
                                        </label>
                                        <input
                                          type="text"
                                          name="wallet"
                                          defaultValue="0x3AD966Dce0a92841bAD721bC6DB0E7D054598f6d"
                                          readOnly
                                          id="mywallet"
                                        />
                                      </div>
                                      <div className="line">
                                        <button
                                          className="ui button primal"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              "0x3AD966Dce0a92841bAD721bC6DB0E7D054598f6d"
                                            );
                                          }}
                                          id="copy_btn"
                                        >
                                          copy wallet
                                        </button>
                                      </div>
                                      <div className="line">
                                        <p>Or scan the QR Code below</p>
                                        <img
                                          id="coin_payment_image"
                                          src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                                            "0x3AD966Dce0a92841bAD721bC6DB0E7D054598f6d"
                                          )}&size=150x150`}
                                          height={150}
                                          width={150}
                                        />
                                      </div>
                                      <form>
                                        <div className="line clearfix">
                                          <button
                                            className="ui button primal float-right"
                                            type="button"
                                            onClick={handleUpload}
                                          >
                                            Confirm Deposit
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="btc-info-bottom">
                                      <div className="bit-hd">
                                        How to buy Bitcoins using
                                        localbitcoins.com
                                      </div>
                                      <div className="pf">
                                        <a
                                          href="https://localbitcoins.com/guides/how-to-buy-bitcoins"
                                          target="_blank"
                                        >
                                          Text tutorial
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {depositinfo.coin == "usdt" && (
                        <div className="funding-method-wrap">
                          <div aria-hidden="false" className>
                            <div className="pay-tabs-content">
                              <div className="ui bottom attached tab segment active">
                                <h2 className="title">Deposit using Tron</h2>
                                <div
                                  className="withdraw-form clearfix bitcoin-from ng-scope"
                                  ng-controller="PayBTC"
                                >
                                  <div>
                                    <div
                                      className="btc-amount"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div>
                                        <center>
                                          <img src="../assets/usdt.png" />
                                        </center>
                                        <span>Amount in base currency:</span>
                                        <b className="ui green inverted header">
                                          {depositinfo.amount}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="center-button">
                                      <div className="line">
                                        <p>
                                          <span>
                                            Note: Make sure that you have sent
                                            the required amount before clicking
                                            on the confirm deposit button.
                                          </span>
                                        </p>
                                      </div>
                                      <div className="line">
                                        <label>
                                          Official Tron Usdt Wallet Address
                                        </label>
                                        <input
                                          type="text"
                                          name="wallet"
                                          defaultValue="TWxVhYwByk52qmzyLTcoyEtikHAyCsKCCC"
                                          readOnly
                                          id="mywallet"
                                        />
                                      </div>
                                      <div className="line">
                                        <button
                                          className="ui button primal"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              "TWxVhYwByk52qmzyLTcoyEtikHAyCsKCCC"
                                            );
                                          }}
                                          id="copy_btn"
                                        >
                                          copy wallet
                                        </button>
                                      </div>
                                      {/* <div className="line">
                                                                            <p>Or scan the QR Code below</p>
                                                                            <img id="coin_payment_image" src="../assets/usdt.jpeg" height={150} width={150} />
                                                                        </div> */}

                                      <div className="line">
                                        <p>Or scan the QR Code below</p>
                                        <img
                                          id="coin_payment_image"
                                          src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                                            "TWxVhYwByk52qmzyLTcoyEtikHAyCsKCCC"
                                          )}&size=150x150`}
                                          height={150}
                                          width={150}
                                        />
                                      </div>
                                      <form>
                                        <div className="line clearfix">
                                          <button
                                            className="ui button primal float-right"
                                            type="button"
                                            onClick={handleUpload}
                                          >
                                            Confirm Deposit
                                          </button>
                                        </div>
                                        {/* <div className="line ui input">
                                                                                <input type="file" name="image1" onChange={handleChange} />
                                                                            </div><br /><br />
                                                                            {
                                                                                imagepresnt && (
                                                                                    <>
                                                                                        <progress id="file" value={progress} max="100"> {progress} </progress>
                                                                                        <div className="line clearfix">
                                                                                            <button className="ui button primal float-right" type="button" onClick={handleUpload}>Upload Proof Of Payment</button>
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                            } */}
                                      </form>
                                    </div>
                                    <div className="btc-info-bottom">
                                      <div className="bit-hd">
                                        How to buy Bitcoins using
                                        localbitcoins.com
                                      </div>
                                      <div className="pf">
                                        <a
                                          href="https://localbitcoins.com/guides/how-to-buy-bitcoins"
                                          target="_blank"
                                        >
                                          Text tutorial
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {depositinfo.coin == "btc" && (
                        <div className="funding-method-wrap">
                          <div aria-hidden="false" className>
                            <div className="pay-tabs-content">
                              <div className="ui bottom attached tab segment active">
                                <h2 className="title">Deposit using Bitcoin</h2>
                                <div
                                  className="withdraw-form clearfix bitcoin-from ng-scope"
                                  ng-controller="PayBTC"
                                >
                                  <div>
                                    <div
                                      className="btc-amount"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div>
                                        <center>
                                          <img src="../assets/btc.png" />
                                        </center>
                                        <span>Amount in base currency:</span>
                                        <b className="ui green inverted header">
                                          {depositinfo.amount}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="center-button">
                                      <div className="line">
                                        <p>
                                          <span>
                                            Note: Make sure that you have sent
                                            the required amount before clicking
                                            on the confirm deposit button.
                                          </span>
                                        </p>
                                      </div>
                                      <div className="line">
                                        <label>
                                          Official Bitcoin Wallet Address
                                        </label>
                                        <input
                                          type="text"
                                          name="wallet"
                                          defaultValue="bc1qxu7zk2d236rf2ndrdah6j3clnaymezyzt32hj2"
                                          readOnly
                                          id="mywallet"
                                        />
                                      </div>
                                      <div className="line">
                                        <button
                                          className="ui button primal"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              "bc1qxu7zk2d236rf2ndrdah6j3clnaymezyzt32hj2"
                                            );
                                          }}
                                          id="copy_btn"
                                        >
                                          copy wallet
                                        </button>
                                      </div>
                                      <div className="line">
                                        <p>Or scan the QR Code below</p>
                                        <img
                                          id="coin_payment_image"
                                          src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                                            "bc1qxu7zk2d236rf2ndrdah6j3clnaymezyzt32hj2"
                                          )}&size=150x150`}
                                          height={150}
                                          width={150}
                                        />
                                      </div>
                                      {/* <div className="line">
                                        <p>Or scan the QR Code below</p>
                                        <img
                                          id="coin_payment_image"
                                          src="../assets/btc.jpeg"
                                          height={150}
                                          width={150}
                                        />
                                      </div> */}
                                      {/* <div className="line">
                                                                            <button className="ui button primal" id="copy_btn">Confirm Deposit</button></div> */}

                                      <form>
                                        <div className="line clearfix">
                                          <button
                                            className="ui button primal float-right"
                                            type="button"
                                            onClick={handleUpload}
                                          >
                                            Confirm Deposit
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="btc-info-bottom">
                                      <div className="bit-hd">
                                        How to buy Bitcoins using
                                        localbitcoins.com
                                      </div>
                                      <div className="pf">
                                        <a
                                          href="https://localbitcoins.com/guides/how-to-buy-bitcoins"
                                          target="_blank"
                                        >
                                          Text tutorial
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="row clearfix">
            <div className="float-left">
              <span className="copy">
                © 2022 <span data-company>Grandx-protrades</span>.{" "}
                <span data-company-address>
                  71 Cherry Court avenue London, United Kingdom
                </span>
              </span>{" "}
              <a href="/en/policies">Privacy policy</a>
            </div>
            <div className="float-right">
              <ul className="soc-list">
                <li>Follow us</li>
                {/*<li>
                    	<div class="footer-media">
		                    <div class="media-icons">
		                        <a href="https://www.facebook.com/Grandx-protrades"><span class="icon-mso-fb"></span></a>
		                    </div>
		                </div>
                    </li>*/}
                <li>
                  <div className="footer-media">
                    <div className="media-icons">
                      <a href="#">
                        <span>
                          <i className="fa fa-instagram" />
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="footer-media">
                    <div className="media-icons">
                      <a href="#">
                        <span>
                          <i className="fa fa-twitter-square" />
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                {/*<li><a href="https://www.linkedin.com/company/Grandx-protrades" class="soc_li"></a></li> 
                    <li><a href="https://twitter.com/Grandx-protradesCom" class="soc_tw"></a></li>
                    <li><a href="https://www.facebook.com/Grandx-protradesCom" class="soc_fb"></a></li>*/}
                {/*<li><a href="" class="soc_yt"></a></li>*/}
              </ul>
            </div>
          </div>
        </footer>{" "}
      </div>
      {/* Yandex.Metrika counter */}
      <noscript aria-hidden="false">
        &lt;div&gt;&lt;img src="https://mc.yandex.ru/watch/38379630"
        style="position:absolute; left:-9999px;" alt="" /&gt;&lt;/div&gt;
      </noscript>
      {/* /Yandex.Metrika counter */}
      <noscript aria-hidden="false">
        &lt;div style="display:inline;"&gt; &lt;img height="1" width="1"
        style="border-style:none;" alt=""
        src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1057644682/?guid=ON&amp;amp;script=0"/&gt;
        &lt;/div&gt;
      </noscript>
      {/* Global site tag (gtag.js) - Google AdWords: 824992907 */}
      {/*StartofTawk.toScript*/}
      {/*End of Tawk.to Script*/}
    </div>
  );
}

export default ProcessFunds;
