import firebase from 'firebase';

// var firebaseConfig = {
//     apiKey: "AIzaSyClajLUJ9dtkQGZTuFgdhp-MGUIYAhee-g",
//     authDomain: "globalfoxtrade-e6d8d.firebaseapp.com",
//     databaseURL: "https://globalfoxtrade-e6d8d-default-rtdb.firebaseio.com",
//     projectId: "globalfoxtrade-e6d8d",
//     storageBucket: "globalfoxtrade-e6d8d.appspot.com",
//     messagingSenderId: "821767893108",
//     appId: "1:821767893108:web:a17220a7fd486a60226121"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAx4493AwPDCyvV0KXW4BD4Zga3VvKYwnk",
    authDomain: "meta-protrade.firebaseapp.com",
    databaseURL: "https://meta-protrade-default-rtdb.firebaseio.com",
    projectId: "meta-protrade",
    storageBucket: "meta-protrade.appspot.com",
    messagingSenderId: "106467149079",
    appId: "1:106467149079:web:9089b839505e88eb432352"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()


